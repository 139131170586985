// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
import "stylesheets/application"
import "packs/surveys"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'

window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.




require("trix")
require("@rails/actiontext")

$(document).on('ready turbolinks:load', function() {
  
  require( "smartwizard/dist/css/smart_wizard_all.css");
  const smartWizard = require("smartwizard");
  $(function() {
      $('#smartwizard-reply').smartWizard({
        theme: 'round',
        toolbar: {
          position: 'bottom',
          extraHtml: [ $('<button></button>').text('Terminar encuesta')
		                        .addClass('sw-btn sw-btn-finish')
		                        .on('click', finish_survey)]
        },
        lang: { // Language variables for button
          next: 'Siguiente',
          previous: 'Atrás'
        }
      });
  });

  function finish_survey(e){
    let audience_survey_reply_match_id = $('#audience_survey_reply_match_id').attr("data-question_id")
    $(e.target).addClass('disabled-opacity').text('Guardando...')
    $('.sw-btn-prev').hide()
    if (typeof(survey_reply_match_id) !== "undefined"){
      $.ajax({
        type: "POST",
        url: "/reply/generate_feedback", 
        data: {audience_survey_reply_match_id: audience_survey_reply_match_id}
      }).then(function(result){
        if(result.ok === true){
          window.location.reload();
        }else{
          $(e.target).removeClass('disabled').text('Terminar encuesta')
        }
      })
    }
  }

  
  let ajaxWasInvoke = false;
  $("#smartwizard-reply").on("leaveStep", function(e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) {
    if (stepDirection == "backward") {
      return true
    }else{    
      let question_id = $('#step-' + currentStepIndex).find('#question_id').attr("data-question_id")
      let survey_reply_match_id = $('#step-' + currentStepIndex).find('#survey_reply_match_id').attr("data-question_id")
      let answer_id = $('input[name=' + question_id + ']:checked').val()
      if(typeof(question_id) === "undefined" || typeof(answer_id) === "undefined" || typeof(survey_reply_match_id) === "undefined"){
        return false
      } else if (ajaxWasInvoke === false) {      
        $.ajax({
          type: "POST",
          url: "/reply/save_reply", 
          data: { question_id: question_id, 
                  answer_id: answer_id, 
                  audience_survey_reply_match_id: survey_reply_match_id
                }
        }).then(function(result){
          if(result.ok === true){
            ajaxWasInvoke = true
            $("#smartwizard-reply").smartWizard("next");
          }        
        })
        return false
      }
    }
    
  });

  $("#smartwizard-reply").on("showStep", function(e, anchorObject, stepIndex, stepDirection, stepPosition) {
    ajaxWasInvoke = false
    if(stepPosition === 'last'){
      $('.sw-btn-next').hide(); // show the button extra only in the last page
      $('.sw-btn-finish').show(); // show the button extra only in the last page
    }else{
      $('.sw-btn-next').show();				
      $('.sw-btn-finish').hide();				
    }
  });

  $('.sortable').sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data('url'),
        type: 'PATCH',
        data: $(this).sortable('serialize')
      });
    }
  });
});