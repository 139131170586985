$(document).on('ready turbolinks:load', function() {
  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    
    $(this).closest('fieldset').find('input, select, textarea').each(function() {
      $(this).prop('required',false);
    });
    return event.preventDefault();
  });
  return $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $($(this).data('listcontainer')).append($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $("trix-file-accept", function(event) {
    event.preventDefault();
  });
});
